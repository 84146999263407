import { render, staticRenderFns } from "./BookmarkComponent.vue?vue&type=template&id=0e2aa1b1&scoped=true&lang=pug&"
import script from "./BookmarkComponent.vue?vue&type=script&lang=ts&"
export * from "./BookmarkComponent.vue?vue&type=script&lang=ts&"
import style0 from "./BookmarkComponent.vue?vue&type=style&index=0&id=0e2aa1b1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e2aa1b1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VTooltip})
